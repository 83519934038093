/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';
// import survivorRecord from './SurvivorRecord.json';
// import { KillerRecord } from './KillerRecord.json';

const gamesPage = css({
  width: '100%',
  height: '100vh',
  backgroundColor: '#202020',
  color: '#8a0707',
  fontFamily: 'Ink Free, sans-serif',
  overflowY: 'scroll',
  overflowX: 'hidden',
});
const headerStyle = css({
  fontSize: '3em',
  fontWeight: 'bold',
});
const bodyStyle = css({
  width: '50%',
  fontSize: '2em',
});
const textBlock = css({
  alignItems: 'center',
  display: 'flex',
  margin: '10px 0px',
  flexDirection: 'column',
  fontSize: '3em',
});
const gameStatsWrapper = css`
  width: 80%;
  margin: 40px auto;
  background-color: #10101090;
  cursor: pointer;
  text-align: left;
  font-size: 2.5em;
  padding: 20px 30px 20px 50px;
  border-radius: 25px;
  &:hover {
    background-color: #101010f0;
  }
  &:before {
    content: '\\2771';
    float: right;
    transform: rotate(90deg);;
  }
`;
const gameStatsContent = css({
  overflow: 'hidden',
  maxHeight: '0',
  transition: 'max-height 0.2s ease-out',
});
const pointRight = css`
  &:before {
    transform: rotate(0deg);
  }
`;

class DBDStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        expanded: false,
        survivorRows: [],
        killerRows: [],
    };
  }

  changeExpansion = (e) => {
    if (this.state.expanded && e.target.id === "dbd-stats-wrapper") {
      e.target.lastChild.style.maxHeight = null;
      this.setState({expanded: false, survivorRows: [], killerRows: []});
    } else if (e.target.id === "dbd-stats-wrapper") {
      let survivorRows = []
      // for (let record in survivorRecord) {
      //   survivorRows.push(`<div>{record.name}</div><div>{record.wins}</div><div>{record.losses}</div>`)
      // }
      e.target.lastChild.style.maxHeight = e.target.lastChild.scrollHeight + "px";
      this.setState({expanded: true, survivorRows: survivorRows});
    } else {
      e.target = e.target.parentElement;
      this.changeExpansion(e);
    }
  };

  render() {
    let wrapperClass = gameStatsWrapper
    if (!this.state.expanded) {
      wrapperClass = [gameStatsWrapper, pointRight];
    }

    return (
      <div id="dbd-stats-wrapper" css={wrapperClass} onClick={this.changeExpansion}>
        Dead By Daylight
        <div id="dbd-stats" css={gameStatsContent}>
          <div id="dbd-killer-stats">
            Killer Records
            {this.state.killerRows}
          </div>
          <div id="dbd-survivor-stats">
            Survivor Records
            {this.state.survivorRows}
          </div>
        </div>
      </div>
    );
  }
}

export default class GameStats extends React.Component {
  render () {
    return (
      <div id="gamesStats" css={gamesPage}>
        <div css={textBlock}>
          <p>Coming Soon!</p>
        </div>
      </div>
    );
  }
}
