/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTiktok, faTwitter, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from "react-router-dom";

import { ReactComponent as OnlyFansLogo } from './OnlyFans_logo.svg';

const headerStyle = css({
  backgroundColor: '#101010',
  fontFamily: 'Ink Free, sans-serif',
  color: '#8a0707',
  position: 'absolute',
  height: '64px',
  width: '100%',
  fontSize: '3em',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  zIndex: '1000',
});
const menuStyle = css({
  verticalAlign: 'sub',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  width: '82px',
  height: '64px',
  alignItems: 'center',
});
const logoStyle = css({
  fontWeight: 'bold',
  cursor: 'default',
  position: 'absolute',
  width: '100%',
  height: '64px',
  top: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '-1',
});
const holderStyle = css({
  display: 'inline-flex',
  justifyContent: 'space-evenly',
  position: 'absolute',
  right: '20px',
})
const baseHolderStyle = css({
  paddingLeft: '5px',
  verticalAlign: 'sub',
  color: '#8a0707',
});
const expandedMenu = css({
  position: 'absolute',
  backgroundColor: '#101010',
  top: '64px',
  left: '0px',
  padding: '0px 20px 20px 20px',
  borderRadius: '0px 0px 45px 0px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  width: 'max-content',
});
const expandLink = css`
  display: block;
  color: #8a0707;
  text-decoration: none;
  border-radius: 10px;
  padding: 0px 10px 0px 10px;
  &:hover {
    background-color: #ffffff0f;
  }
`;

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  expandMenu = () => {
    if (this.state.expanded) {
      this.setState({expanded: false});
    } else {
      this.setState({expanded: true});
    }
  };

  render() {
    return (
      <nav className="navbar navbar-expand-lg" css={headerStyle} id="sa-header">
        <div className="container-fluid">
        <div id="hamburgerMenu" css={menuStyle} onMouseEnter={this.expandMenu} onMouseLeave={this.expandMenu}>
          <FontAwesomeIcon icon={faBars} css={baseHolderStyle} />
          { this.state.expanded &&
            <div id="expandedMenu" css={expandedMenu}>
              <NavLink
                to="/"
                css={expandLink}
              >
                Home
              </NavLink>
              <NavLink
                to="/shop"
                css={expandLink}
              >
                Shop
              </NavLink>
              <a
                href="https://www.worldanvil.com/w/endtomia-shadowarmada"
                target="_blank"
                css={expandLink}
              >
                Endtomia
              </a>
              <a
                href="https://www.youtube.com/channel/UCuXziP9vNX5ekPxEoBlIpoQ?sub_confirmation=1"
                target="_blank"
                css={expandLink}
              >
                Youtube
              </a>
              <a
                href="https://www.twitch.tv/shadowarmada"
                target="_blank"
                css={expandLink}
              >
                Twitch
              </a>
              <NavLink
                to="/games"
                css={expandLink}
              >
                Games
              </NavLink>
              <NavLink
                to="game-stats"
                css={expandLink}
              >
                Game Stats
              </NavLink>
            </div>
          }
        </div>
        <div id="webLogo" className="d-none d-lg-flex" css={logoStyle} >
          Shadow Armada
        </div>
        <div id="linksHolder" css={holderStyle} >
          <div id="tiktokHolder" >
            <a href="https://www.tiktok.com/@shadowarmada" target="_blank">
              <FontAwesomeIcon icon={faTiktok} css={baseHolderStyle} />
            </a>
          </div>
          <div id="youtubeHolder" >
            <a href="https://www.youtube.com/channel/UCuXziP9vNX5ekPxEoBlIpoQ?sub_confirmation=1" target="_blank">
              <FontAwesomeIcon icon={faYoutube} css={baseHolderStyle} />
            </a>
          </div>
          <div id="twitchHolder" >
            <a href="https://www.twitch.tv/shadowarmada" target="_blank">
              <FontAwesomeIcon icon={faTwitch} css={baseHolderStyle} />
            </a>
          </div>
          <div id="twitterHolder" >
            <a href="https://twitter.com/shadowarmada98" target="_blank">
              <FontAwesomeIcon icon={faTwitter} css={baseHolderStyle} />
            </a>
          </div>
          <div id="ofHolder">
            <a href="https://onlyfans.com/shadowarmada" target="_blank">
              <OnlyFansLogo className="svg-inline--fa" css={baseHolderStyle} />
            </a>
          </div>
        </div>
        </div>
      </nav>
    );
  }
}
