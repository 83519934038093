/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react';
import { css, jsx } from '@emotion/react';

const mySchedule = [
    {
        "day": "Monday",
        "time": "6pm America/Detroit",
        "title": "Solo Game Livestream",
        "locations": [
            {
                "link": "https://tiktok.com/@shadowarmada",
                "title": "TikTok"
            },
            {
                "link": "https://twitch.tv/shadowarmada",
                "title": "Twitch"
            },
            {
                "link": "https://youtube.com/shadowarmada",
                "title": "YouTube"
            }
        ]
    },
    {
        "day": "Wednesday",
        "time": "10pm America/Detroit",
        "title": "Red Light Video",
        "locations": [
            {
                "link": "https://onlyfans.com/shadowarmada",
                "title": "OnlyFans"
            }
        ]
    },
    {
        "day": "Friday",
        "time": "6pm America/Detroit",
        "title": "Game Livestream (Often with Friends/Family)",
        "locations": [
            {
                "link": "https://twitch.tv/shadowarmada",
                "title": "Twitch"
            },
            {
                "link": "https://youtube.com/shadowarmada",
                "title": "YouTube"
            }
        ]
    },
    {
        "day": "Saturday",
        "time": "2pm America/Detroit",
        "title": "Game Livestream (Often with Friends/Family)",
        "locations": [
            {
                "link": "https://twitch.tv/shadowarmada",
                "title": "Twitch"
            },
            {
                "link": "https://youtube.com/shadowarmada",
                "title": "YouTube"
            }
        ]
    }
];

const tableStyle = css({
    width: '50%',
    fontSize: '2em',
});

const tableRowStyle = css({
    borderTop: "solid #434343 2px",
    borderBottom: "solid #434343 2px"
});

const tableCellStyle = css({
    padding: "10px 0px",
    textAlign: "center"
})

export class ScheduleRow extends React.Component {
    render () {
        return (
            <tr css={tableRowStyle}>
                <td css={tableCellStyle}>{this.props.myEvent.day}</td>
                <td css={tableCellStyle}>
                    <div>{this.props.myEvent.title}</div>
                    <div>{this.props.myEvent.time}</div>
                    <div>{this.props.myEvent.locations.map((item, _index) => (<span>-<a href={item.link}>{item.title}</a>-</span>))}</div>
                </td>
            </tr>
        )
    }
}

export default class Schedule extends React.Component {
    render () {
        return (
            <table css={tableStyle}>
                <tbody>
                    { mySchedule.map((item, _index) => (<ScheduleRow myEvent={item} />))}
                </tbody>
            </table>
        );
    }
}